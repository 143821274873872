import Swiper from 'swiper/bundle';

const swiperTools = new Swiper('.tools-swiper', {
  slidesPerView: 'auto',
  breakpoints: {
    900: {
      allowTouchMove: false,
    },
  }
});

const swiperAdvantages = new Swiper('.advantages-swiper', {
  slidesPerView: 'auto',
  breakpoints: {
    900: {
      allowTouchMove: false,
    },
  }
});

const swiperHowDoesItWork = new Swiper('.howDoesItWork-swiper', {
  slidesPerView: 'auto',
  breakpoints: {
    900: {
      allowTouchMove: false,
    },
  }
});

const tabSelector = 'products__item';
const activeClass = `${tabSelector}--active`;

const productsTabs = document.querySelectorAll(`.${tabSelector}`);

productsTabs.forEach((tab) => tab.addEventListener('click', (e) => {
  const activeTab = document.querySelector(`.${activeClass}`);
  activeTab.classList.remove(activeClass);

  e.target.closest(`.${tabSelector}`).classList.add(activeClass);
}));